
import { computed, defineComponent, PropType } from 'vue'
import { ElInput, ElDatePicker, ElSwitch } from 'element-plus'

import { ITableFilterValueOption } from '@/types'

export default defineComponent({
  name: 'AppTableFilters',

  components: {
    ElInput,
    ElDatePicker,
    ElSwitch,
  },

  props: {
    modelValue: {
      required: true,
    },
    item: {
      required: true,
      type: Object as PropType<ITableFilterValueOption>,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const componentType = computed(() => {
      switch (props.item.type) {
        case 'number':
        case 'string':
        case 'array':
          return ElInput
        case 'date':
          return ElDatePicker
        case 'boolean':
          return ElSwitch
        default:
          return ElInput
      }
    })

    const inputType = computed(() => {
      switch (props.item.type) {
        case 'number':
          return 'number'
        case 'array':
        case 'string':
          return 'string'
        case 'date':
          return 'date'
        case 'boolean':
          return ''
        default:
          return 'string'
      }
    })

    const onInput = (value: any) => {
      emit('update:modelValue', value)
    }

    return {
      componentType,
      inputType,
      onInput,
    }
  },
})
