import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), {
    "model-value": _ctx.modelValue,
    type: _ctx.inputType,
    placeholder: 
      _ctx.item.type === 'array'
        ? 'Enter value splitting by comma (,)'
        : 'Enter value'
    ,
    "active-text": "Yes",
    "inactive-text": "No",
    format: "MM-DD-YYYY",
    onInput: _ctx.onInput,
    "onUpdate:modelValue": _ctx.onInput
  }, null, 40, ["model-value", "type", "placeholder", "onInput", "onUpdate:modelValue"]))
}