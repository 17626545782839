
import { computed, defineComponent, PropType } from 'vue'
import {
  ElButton,
  ElIcon,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElInput,
} from 'element-plus'
import { ArrowDown, CirclePlus, Delete } from '@element-plus/icons-vue'

import { ITableFilterOption, ITableFilterValueOption } from '@/types'

import FilterItem from './FilterItem.vue'

export default defineComponent({
  name: 'AppTableFilters',

  components: {
    ElButton,
    ElIcon,
    ArrowDown,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    CirclePlus,
    FilterItem,
  },

  props: {
    modelValue: {
      required: true,
      type: Array as PropType<ITableFilterValueOption[]>,
    },
    options: {
      type: Array as PropType<ITableFilterOption[]>,
      default: () => [],
    },
  },

  emits: ['update:modelValue', 'filterItemChanged'],

  setup(props, { emit }) {
    const modelItemKeys = computed(() => {
      return props.modelValue.map((item) => item.key)
    })

    const addItem = (item: ITableFilterOption) => {
      const modelValueTemp = [...props.modelValue]
      const valueItem: ITableFilterValueOption = {
        label: item.label,
        key: item.key,
        type: item.type,
        value: null,
      }

      modelValueTemp.push(valueItem)

      emit('update:modelValue', modelValueTemp)
    }

    const removeItem = (item: ITableFilterValueOption) => {
      const modelValueTemp = [...props.modelValue]

      const itemIndex = modelValueTemp.findIndex(
        (modelItem) => modelItem.key === item.key,
      )
      if (itemIndex !== -1) {
        modelValueTemp.splice(itemIndex, 1)
      }

      emit('update:modelValue', modelValueTemp)

      if (item.value !== null) {
        emit('filterItemChanged')
      }
    }

    const onFilterItemChange = () => {
      emit('filterItemChanged')
    }

    return {
      modelItemKeys,
      addItem,
      removeItem,
      Delete,
      ElInput,
      onFilterItemChange,
    }
  },
})
