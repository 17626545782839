
import { defineComponent, ref, onMounted, computed } from 'vue'
import {
  ElButton,
  ElIcon,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElInput,
} from 'element-plus'
import { CirclePlus, Search } from '@element-plus/icons-vue'
import { useDebounceFn } from '@vueuse/core'
import { useRouter } from 'vue-router'

import { apiErrorNotification } from '@/core/helpers'
import { localeDate, localeNumber } from '@/core/utils'
import {
  useProducts,
  usePagination,
  useSort,
  usePermissions,
} from '@/core/compositions'
import { routeNames } from '@/router'
import {
  EPermissions,
  ITableFilterOption,
  ITableFilterValueOption,
  TIndexedObject,
} from '@/types'

import AppTableFilters from '@/components/stateless/AppTableFilters/AppTableFilters.vue'

export default defineComponent({
  name: 'ProductsView',

  components: {
    ElButton,
    ElIcon,
    CirclePlus,
    ElTable,
    ElTableColumn,
    ElPagination,
    ElInput,

    AppTableFilters,
  },

  setup() {
    const router = useRouter()

    const {
      total,
      page,
      onUpdatePage,
      onUpdateTotal,
      onUpdateTake,
      take,
      skip,
    } = usePagination(routeNames.products)
    const { sortKey, sortValue, updateSort } = useSort()
    const { hasPermission } = usePermissions()

    const { getProductsPagination, products } = useProducts()

    const loading = ref(true)
    const searchValue = ref(null)
    const filters = ref<ITableFilterValueOption[]>([])

    const filterOptions: ITableFilterOption[] = [
      {
        label: 'ID',
        key: 'id',
        type: 'number',
      },
      {
        label: 'Date from',
        key: 'startDate',
        type: 'date',
      },
      {
        label: 'Date to',
        key: 'endDate',
        type: 'date',
      },
      {
        label: 'Price',
        key: 'price',
        type: 'number',
      },
      {
        label: 'Min pictures count',
        key: 'minPicturesCount',
        type: 'number',
      },
      {
        label: 'Max pictures count',
        key: 'maxPicturesCount',
        type: 'number',
      },
      {
        label: 'With thumbnail',
        key: 'hasThumbnail',
        type: 'boolean',
      },
      {
        label: 'Image required in poster',
        key: 'imageRequiredInPoster',
        type: 'boolean',
      },
      {
        label: 'Video required in poster',
        key: 'videoRequiredInPoster',
        type: 'boolean',
      },
      {
        label: 'Archived',
        key: 'isArchived',
        type: 'boolean',
      },
    ]

    const filtersObject = computed(() => {
      const obj: TIndexedObject = {}

      filters.value.forEach((filter) => {
        if (filter.value !== null) {
          obj[filter.key] = filter.value
        }
      })

      return obj
    })

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      loading.value = true

      try {
        const productsPagination = await getProductsPagination({
          take: take.value,
          skip: skip.value,
          sortKey: sortKey.value as string,
          sortValue: sortValue.value as string,
          ...(searchValue.value
            ? { search: searchValue.value as unknown as string }
            : null),
          ...filtersObject.value,
        })

        onUpdateTotal(productsPagination.count)
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onPageChange = (page: number) => {
      onUpdatePage(page)

      getData()
    }

    const onSizeChange = (size: number) => {
      onUpdateTake(size)

      getData()
    }

    const onSortChange = (sort: { prop: string; order: string }) => {
      updateSort(sort.prop, sort.order)

      getData()
    }

    const onSearchChange = useDebounceFn(() => {
      onUpdatePage(1)

      getData()
    }, 700)

    const onFilterChanged = useDebounceFn(() => {
      onUpdatePage(1)

      getData()
    }, 700)

    const toCreateProduct = () => {
      router.push({ name: routeNames.createProduct })
    }

    const onRowClick = (row: any) => {
      if (hasPermission(EPermissions.ViewProduct)) {
        router.push({ name: routeNames.product, params: { id: row.id } })
      }
    }

    return {
      total,
      page,
      take,
      onPageChange,
      onUpdatePage,
      onSizeChange,
      localeDate,
      localeNumber,
      loading,
      products,
      onSortChange,
      Search,
      searchValue,
      onSearchChange,
      filters,
      filterOptions,
      onFilterChanged,
      hasPermission,
      EPermissions,
      toCreateProduct,
      onRowClick,
    }
  },
})
